window.ko = require('knockout');

require('knockout-mapping');

require('knockout.validation');
require('knockout.validation/localization/fr-FR');

require('core/knockout');

ko.validation.init({
    messagesOnModified: false,
    decorateElementOnModified: false,
    // decorateInputElement: true,
    errorElementClass: 'has-error',
    grouping: {
        deep: true,
        live: true,
        observable: true
    }
});

const prosanteComponentLoader = {
    loadViewModel(name, viewModelConfig, callback) {
        callback(function (params, componentInfo) {
            if (params && params.forceViewModel)
                return params.forceViewModel;

            // Conditions tirées des cas les plus plausibles que l'on utilise, provenant 
            // du body de la fonction "resolveViewModel" du defaultLoader de knockout
            // https://github.com/knockout/knockout/blob/v3.4.2/src/components/defaultLoader.js#L133
            if (typeof viewModelConfig === 'function')
                return new viewModelConfig(params);

            if (typeof viewModelConfig['createViewModel'] === 'function')
                return viewModelConfig['createViewModel'](params, componentInfo);

            if ('instance' in viewModelConfig)
                return viewModelConfig['instance'];
        });
    }
};

ko.components.loaders.unshift(prosanteComponentLoader);

ko.validation.locale('fr-FR');