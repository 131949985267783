ko.extenders.isCurrency = function (target, options) {
    target.formatted = ko.pureComputed(() => {
        const value = ko.utils.unwrapObservable(target());
        return value ? parseFloat(value).toFixed(2) + '$' : '0.00$';
    });

    return target;
};

ko.extenders.round = function(target, decimals) {
    target.formatted = ko.pureComputed({
        read: () => {
            const value = ko.utils.unwrapObservable(target());
            return value ? parseFloat(value).toFixed(decimals) : `0.${'0'.repeat(decimals)}`;
        },
        write: value => target(parseFloat(value))
    });
    
    return target;
};