require('./custom-components');
require('./custom-bindings');
require('./custom-extenders');
require('./custom-validations');

ko.numericObservable = () => {
    let _inner = ko.observable();
    return ko.pureComputed({
        read: _inner,
        write: value => _inner(parseInt(value, 10))
    });
};