import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dropzone from 'dropzone';

require('@ckeditor/ckeditor5-build-classic/build/translations/' + abp.localization.currentLanguage.name + '.js');

ko.bindingHandlers.icheck = {
    init(element, valueAccessor, allBindingsAccessor) {
        const observable = valueAccessor();
        const value = allBindingsAccessor().value;
        const toggle = allBindingsAccessor().toggle;

        let isElementChange = false;
        let isObservableChange = false;

        if (!toggle) {
            $(element).iCheck({
                checkboxClass: 'icheckbox_flat-grey',
                radioClass: 'iradio_flat-grey'
            });

            $(element).on("ifChanged", function () {
                updateObservable(this);
            });

            observable.subscribe(updateElement);

            updateElement(ko.utils.unwrapObservable(observable));
        }
        else {
            if (observable())
                $(element).attr('checked', 'checked');

            $(element).bootstrapToggle({
                on: L(toggle.on),
                off: L(toggle.off)
            });

            $(element).change(function () {
                updateObservable(this);
            });
        }

        function updateObservable(obj) {
            isElementChange = true;

            if (!isObservableChange) {
                if (ko.isObservable(observable)) {
                    if (observable() === true || observable() === false)
                        observable(obj.checked);
                    else if (obj.value != 'on' && obj.value != 'off')
                        observable(obj.value);
                }
            }

            isElementChange = false;
        }

        function updateElement(value) {
            isObservableChange = true;

            if (!isElementChange) {
                const val = $(element).val();
                $(element).iCheck(val == value || value === true ? 'check' : 'uncheck');
            }

            isObservableChange = false;
        }
    }
};

ko.bindingHandlers.datepicker = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        //initialize datepicker with some optional options
        let options = _.merge({
            format: 'YYYY-MM-DD',
            locale: abp.localization.currentLanguage.name
        }, allBindingsAccessor().dateTimePickerOptions || {});

        $(element).datetimepicker(options);

        ko.utils.registerEventHandler(element, "dp.change", (e) => {
            let value = valueAccessor();

            if (ko.isObservable(value)) {
                if (!e.date || value().format(options.format) !== e.date.format(options.format))
                    value(e.date ? e.date.utc() : '');
            }
        });

        $(element).on('click', function () {
            this.select();
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            let picker = $(element).data("DateTimePicker");

            if (picker)
                picker.destroy();
        });
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        let options = _.merge({
            format: 'YYYY-MM-DD',
            locale: abp.localization.currentLanguage.name
        }, allBindingsAccessor().dateTimePickerOptions || {});

        let picker = $(element).data("DateTimePicker");

        if (picker) {
            let koDate = ko.utils.unwrapObservable(valueAccessor());
            $(element).val(koDate ? moment(koDate).format(options.format) : '');
        }
    }
};

ko.bindingHandlers.ckeditor = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        let observable = valueAccessor();
        let options = {
            language: abp.localization.currentLanguage.name,
            coreStyles_italic: { element: 'i', overrides: 'em' },
            toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'imageUpload', 'blockQuote', 'insertTable', 'undo', 'redo'],
            ckfinder: {
                uploadUrl: '/Upload/QuickUpload'
            }
        };

        ClassicEditor.create(element, options).then(editor => {
            bindingContext.$root.ckEditors = bindingContext.$root.ckEditors || ko.observableArray();
            bindingContext.$root.ckEditors.push(editor);
            editor.setData(observable() || '');

            let isObservableChange = false;
            let isEditorChange = false;

            editor.model.document.on('change', function () {
                if (!isObservableChange) {
                    isEditorChange = true;
                    observable(editor.getData());
                    isEditorChange = false;
                }
            });

            observable.subscribe(() => {
                if (!isEditorChange) {
                    isObservableChange = true;
                    editor.setData(observable());
                    isObservableChange = false;
                }
            });

            //handle disposal (if KO removes by the template binding)
            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                if (editor) {
                    bindingContext.$root.ckEditors.remove(editor);
                    editor.destroy();
                }
            });
        });
    }
};

ko.bindingHandlers.modal = {
    init: function (element, valueAccessor) {
        const value = valueAccessor();

        $(element).appendTo('body');
        $(element).modal({ show: false });

        if (ko.isObservable(value)) {
            $(element).on('hidden.bs.modal', function () {
                value(false);
            });

            value.subscribe(newValue => {
                $(element).modal(newValue ? 'show' : 'hide');
            });
        }
    }
};

ko.bindingHandlers.money = {
    update: function (element, valueAccessor, allBindingsAccessor) {
        const value = ko.utils.unwrapObservable(valueAccessor());
        const text = value ? value.toFixed(2) + '$' : '0.00$';

        $(element).text(text);
    }
};

ko.bindingHandlers.date = {
    update: function (element, valueAccessor, allBindingsAccessor) {
        const value = ko.utils.unwrapObservable(valueAccessor());
        const format = allBindingsAccessor().format || 'YYYY-MM-DD';

        let date = value ? moment.utc(value).format(format) : '';
        if ($(element).is('input'))
            $(element).val(date);
        else
            $(element).text(date);
    }
};

ko.bindingHandlers.textLocalize = {
    update: function (element, valueAccessor, allBindingsAccessor) {
        const value = ko.utils.unwrapObservable(valueAccessor());
        const source = allBindingsAccessor().sourceName || localizationSourceName;
        const isRequired = ko.utils.unwrapObservable(allBindingsAccessor().isRequired) || false;

        $(element).text(L(value, source) + (isRequired ? '*' : ''));
    }
};

ko.bindingHandlers.placeholderLocalize = {
    update: function (element, valueAccessor, allBindingsAccessor) {
        const value = valueAccessor();
        const source = allBindingsAccessor().sourceName || localizationSourceName;

        $(element).attr('placeholder', L(value, source));
    }
};

ko.bindingHandlers.titleLocalize = {
    update: function (element, valueAccessor, allBindingsAccessor) {
        const value = valueAccessor();
        const source = allBindingsAccessor().sourceName || localizationSourceName;

        $(element).attr('title', L(ko.utils.unwrapObservable(value), source));
    }
};

ko.bindingHandlers.documentTitle = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        const documentTitle = allBindingsAccessor().documentTitle || { priority: 0, separator: " " };

        documentTitle.priority = documentTitle.priority ? documentTitle.priority : 0;
        documentTitle.separator = documentTitle.separator ? documentTitle.separator : " ";

        $(element).addClass('document-title');
        $(element).attr('data-priority-title', documentTitle.priority);

        $(document).on("keyup", ".document-title", function (e) {
            buildTitle();
        });

        function buildTitle() {
            let objects = $('.document-title').sort(function (a, b) {
                return ($(b).data('priority-title') < $(a).data('priority-title')) ? 1 : -1;
            });

            let title = _.map(objects, o => { return $(o).val(); });
            title = title.join(documentTitle.separator);

            document.title = title + " - " + window.title;
        }

        buildTitle();
    }
};

ko.bindingHandlers.tabTitle = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        const tabTitle = allBindingsAccessor().tabTitle || { priority: 0, separator: " " };

        tabTitle.priority = tabTitle.priority ? tabTitle.priority : 0;
        tabTitle.separator = tabTitle.separator ? tabTitle.separator : " ";

        $(element).addClass('tab-title');
        $(element).attr('data-priority-title', tabTitle.priority);

        $(document).on("keyup", ".tab-title", function (e) {
            buildTitle();
        });

        function buildTitle() {
            let objects = $('.tab-title').sort(function (a, b) {
                return ($(b).data('priority-title') < $(a).data('priority-title')) ? 1 : -1;
            });

            let title = _.map(objects, o => { return $(o).val(); });
            title = title.join(tabTitle.separator);

            if (title != " " && title != "")
                $('.main-tab').text(title);
        }

        buildTitle();
    }
};

ko.bindingHandlers.select2 = {
    init: function (el, valueAccessor, allBindingsAccessor, viewModel) {
        ko.utils.domNodeDisposal.addDisposeCallback(el, function () {
            $(el).select2('destroy');
        });

        let allBindings = allBindingsAccessor();
        let select2 = ko.utils.unwrapObservable(allBindings.select2);

        if (select2.isHtml) {
            let template = (d) => $('<div>' + d.text + '</div>');
            select2.templateResult = template;
            select2.templateSelection = template;
        }

        if (!select2.language)
            select2.language = abp.localization.currentLanguage.name;

        if (select2.placeholder)
            select2.placeholder = L(select2.placeholder, select2.placeholderParams);

        $(el).select2(select2);

        if (select2.key) {

            $(el).on('select2:select', function (e) {
                let select = e.params.data;

                if (select.id && !select._resultId) {
                    abp.services.prosante.extensibleSelectOptions.create({ text: select.text, key: select2.key })
                        .then(result => {
                            $(el).find('option[value="' + select.text + '"]').remove().trigger('change');
                            allBindings.options.push(result);

                            if ("value" in allBindings)
                                allBindings.value(result.id);
                            else if ("selectedOptions" in allBindings)
                                allBindings.selectedOptions.push(result.id);
                        });
                }
            });
        }
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        let allBindings = allBindingsAccessor();

        if ("value" in allBindings && ko.isObservable(allBindings.value))
            $(element).val(allBindings.value()).trigger('change');
    }
};

ko.bindingHandlers.enterKeyPress = {
    init: function (element, valueAccessor) {
        const fn = ko.utils.unwrapObservable(valueAccessor());

        $(element).keypress(e => {
            if (e.which == 13)
                fn();
        });
    }
};

ko.bindingHandlers.dropzone = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        const value = ko.unwrap(valueAccessor());
        const callback = value.callback || _.noop;
        const options = value.options || {};
        const clickable = value.clickable || false;
        const multiple = value.multiple || false;
        const encrypt = value.encrypt || false;
        const acceptedFiles = value.acceptedFiles;

        let success = (files, response) => callback(response.result);

        let sending = (file, xhr, formData) => {
            if (!formData.get('__RequestVerificationToken')) {
                formData.append('__RequestVerificationToken', abp.security.antiForgery.getToken());
                formData.append('encrypt', encrypt);

                for (let [key, value] of Object.entries(options))
                    formData.append(key, value);
            }
        };

        new Dropzone(element, {
            clickable: clickable,
            maxFileSize: 1024000,
            uploadMultiple: multiple,
            parallelUploads: 100,
            maxFiles: 30,
            addRemoveLinks: true,
            acceptedFiles: acceptedFiles,
            url: window.location.origin + '/Upload/Upload',
            sending: sending,
            successmultiple: success,
            success: success,
            dictDefaultMessage: multiple ? L('DropFilesHereToUploadOrClickToAdd') : L('DropFileHereToUploadOrClickToAdd')
        });
    }
};

ko.bindingHandlers.popover = {
    init: function (element, valueAccessor) {
        let options = _.merge({
            placement: 'top',
            trigger: 'hover'
        }, ko.utils.unwrapObservable(valueAccessor()) || {});

        $(element).popover(options);
    },
    update: function (element, valueAccessor) {
        let popover = $(element).data('bs.popover');
        let options = _.merge(popover.options, ko.utils.unwrapObservable(valueAccessor()) || {});

        $(element).popover(options);
        $(element).popover('hide');
    }
};