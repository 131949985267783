/*
Wiki: https://github.com/Knockout-Contrib/Knockout-Validation/wiki
Native Rules
- required, min, max, minLength, maxLength, pattern,
- step, email, phoneUS, equal, notEqual, date,
- dateISO, number, digit, unique
*/

ko.validation.rules['biggerHour'] = {
    validator: (value, params) => params ? moment(value, 'HH:mm').diff(moment(params, 'HH:mm')) > 0 : true,
    message: '[Définir le message]'
};

ko.validation.rules['smallerHour'] = {
    validator: (value, params) => params ? moment(value, 'HH:mm').diff(moment(params, 'HH:mm')) < 0 : true,
    message: '[Définir le message]'
};

ko.validation.rules['biggerOrEqualDate'] = {
    validator(value, params) {
        let otherValue = ko.utils.unwrapObservable(params.value);
        let format = params.format || 'YYYY-MM-DD HH:mm';

        return otherValue ? moment(value, format).diff(moment(otherValue, format)) >= 0 : true;
    },
    message: '[Définir le message]'
};

ko.validation.rules['smallerOrEqualDate'] = {
    validator(value, params) {
        let otherValue = ko.utils.unwrapObservable(params.value);
        let format = params.format || 'YYYY-MM-DD HH:mm';

        return otherValue ? moment(value, format).diff(moment(otherValue, format)) <= 0 : true;
    },
    message: '[Définir le message]'
};

ko.validation.rules['lessOrEqualTo'] = {
    validator: (value, params) => value <= params,
    message: '[Définir le message]'
};

ko.validation.rules['numberLessOrEqualTo'] = {
    validator: (value, params) => parseFloat(value) <= parseFloat(params),
    message: '[Définir le message]'
};

ko.validation.rules['numberMin'] = {
    validator: (value, params) => parseFloat(value) >= parseFloat(params),
    message: '[Définir le message]'
};

ko.validation.rules['looseEqual'] = {
    validator: (value, params) => value === ko.validation.utils.getValue(params),
    message: '[Values must equal.]'
};

ko.validation.rules['numberEqual'] = {
    validator: (value, params) => parseFloat(value) === parseFloat(params),
    message: '[Values must equal.]'
};

ko.validation.rules['minArrayLength'] = {
    validator: (obj, params) => obj && obj.length >= params,
    message: '[Définir le message]'
};

ko.validation.rules['arrayRequired'] = {
    validator: (obj, required) => required && obj && obj.length > 0,
    message: L('FieldRequired')
};

ko.validation.rules['patterns'] = {
    validator(value, params) {
        var patterns = ko.validation.utils.getValue(params);

        for (i = 0; i < patterns.length; i++) {
            if (!value.search(patterns[i]).length > 0)
                return false;
        }

        return true;
    },
    message: '[Définir le message]'
};

ko.validation.rules['url'] = {
    validator(value, params) {
        if (!value)
            return true;

        value = value.replace(/^\s+|\s+$/, '');
        return value.match(/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.‌​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[‌​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1‌​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00‌​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u‌​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i);
    },
    message: L('FieldMustBeUrl')
};