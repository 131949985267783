class KoSlot {
    constructor({ name, nodes, data }) {
        this.name = name;
            
        let slotNode = nodes.find(el => el.slot === this.name);
        let childNodes = slotNode ? Array.from(slotNode.childNodes) : [];

        this.nodes = childNodes.map(n => n.cloneNode(true));
        this.data = data;
    }
}

ko.components.register('ko-slot', {
    viewModel: KoSlot,
    template: require('./ko-slot.html')
});